<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="
        `align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0`)
      " :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Звіт про продажі по IMEI/SN</h1>
        </v-card-title>

        <v-tabs v-model="tab" class="pb-1 card">
            <v-tab>
                <span>
                    <v-icon class="mr-2">mdi-chart-bar-stacked</v-icon>Звіт про продажі по IMEI/SN
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>

                <v-card class="mb-4 px-0 px-md-2" outlined width="100%" elevation="4">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-select @change="checkShops()" :items="imeiTypeList" label="Тип звіту" v-model="imeiType" clearable></v-select>
                                <h4>За період*:</h4>
                                <v-row>
                                    <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker :max="Now()" first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateFrom" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker :max="Now()" first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateTo" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <div class="mt-4 mb-6 mb-md-0">
                                    <v-radio-group v-model="salesType" row>
                                        <v-radio label="є продажі" value="sales"></v-radio>
                                        <v-radio label="немає продажів" value="noSales"></v-radio>
                                    </v-radio-group>
                                    <v-file-input accept=".xlsx" :disabled="checkFileForm" v-model="file" show-size hint="Формат Excel (.xlsx)" persistent-hint label="Додати файл *"></v-file-input>
                                    <div class="caption grey--text ml-8">
                                        - 1 колонка – Номер ТТ<br>
                                        - 2 колонка – Дата продажу у форматі ДД/ММ/РРРР<br>
                                        - 3 колонка – IMEI/SN<br>
                                        - 4 колонка – Ціна продажу
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field class="my-1" label="Перший рядок з даними" v-model="firstRow"></v-text-field>
                                <v-row>
                                    <v-col cols="12" sm="6" md="12" lg="8">
                                        <h4 class="my-4">Оберіть магазини для завантаження з файлу *:</h4>
                                        <v-checkbox @click="checkAllShops()" hide-details class="mr-2 mb-5" label="Вибрати всі магазини" v-model="allShops"></v-checkbox>
                                        <div v-for="(el, key) in shopsList" :key="key" class="d-flex">
                                            <v-checkbox @click="checkShopQty(el.shop_id)" :disabled="el.is_inactive" hide-details class="mr-2 mt-2" v-model="qtyS[el.shop_id]"></v-checkbox>
                                            <v-text-field readonly :disabled="el.is_inactive || !qtyS[el.shop_id]" class="mt-n2" :label="el.name" v-model="qty[el.shop_id]"></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mt-4">
                        <v-btn color="primary" @click="downloadReport()" :loading="listLoading" :disabled="checkForm">Далі</v-btn>
                        <v-btn @click="clearForm()">Очистити</v-btn>
                    </v-card-actions>
                </v-card>

            </v-tab-item>
        </v-tabs-items>

        <!-- Фильтр -->
        <h4 class="second--text mt-8 mb-4 title-2">Звіти:</h4>
        <v-card width="100%" class="mb-4 blue lighten-5 px-0 px-md-2" flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                        <h4>Період продажів:</h4>
                        <v-row>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedDateFromS" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateFromS" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedDateToS" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="dateToS" @input="menu4 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-select @change="checkShops()" :items="imeiTypeList" label="Тип звіту" v-model="imeiTypeS" clearable></v-select>

                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-4">Магазин:</h4>
                        <div>
                            <v-checkbox @click="checkAllShopsV()" hide-details class="mr-2 mb-5" label="Вибрати всі магазини" v-model="allShopsV"></v-checkbox>
                            <v-checkbox hide-details v-for="(el, key) in shopsList" :key="key" class="mr-2 mt-0 mb-5" v-model="shopS[el.shop_id]" :label="el.name"></v-checkbox>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-4">
                <v-btn color="primary" @click="loadList()">Шукати</v-btn>
                <v-btn @click="clearSearchForm()">Очистити</v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="d-flex align-center body-2 px-0 my-2" flat>
            <v-btn text :loading="xlsReportLoading" :disabled="totalItems > 0 ? false : true" @click="createGeneralXlsx()">
                <span class="mr-2">
                    <v-icon color="green darken-3">{{exclelIcon}}</v-icon>
                </span>
                Завантажити
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                Знайдено: {{totalItems}}
            </div>
        </v-card>

        <v-data-table multi-sort :loading="listLoading" :headers="filteredHeaders" class="elevation-1 report" :items="imeiList" :footer-props="footerProps" :options.sync="options" mobile-breakpoint="0">

            <template v-slot:item.start_date="{ item }">
                {{ toDate(item.start_date) }}
            </template>
            <template v-slot:item.finish_date="{ item }">
                {{ toDate(item.finish_date) }}
            </template>
            <template v-slot:item.category="{ item }">
                <span v-html="item.category"></span>
            </template>
            <template v-slot:item.msc="{ item }">
                <div v-if="item.msc ==  0">
                    ТТ не зареєстрована в MSC 2.0
                </div>
                <div v-if="item.msc ==  1">
                    <div>ТТ активна в MSC 2.0</div>
                    <div class="font-weight-bold">Код: {{item.msc_code}}</div>
                    <div>{{item.msc_comment}}</div>
                </div>
            </template>
            <template v-slot:item.download="{ item }">
                <v-btn text @click="exportR(item)" class="px-0" :disabled="xlsLoading">
                    <v-icon color="green darken-3" :small="$vuetify.breakpoint.smAndDown ? true : false">{{exclelIcon}}</v-icon>
                </v-btn>
            </template>
            <template v-slot:no-data> Немає данних </template>
            <template v-slot:loading> Зачекайте... </template>
        </v-data-table>

    </v-card>

    <v-dialog v-model="errorsDialog" width="600" max-width="100%" class="error">
        <v-card width="100%">
            <v-card-title class="error white--text">Помилка!</v-card-title>
            <v-card-text class="mt-6">
                <p v-html="errorsTxt || errorText"></p>
                <p v-if="!total && !errorsTxt && !errorText">Файл не містить данних для імпорту.</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="errorsDialog=false">Закрити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="successDialog" persistent width="600" max-width="100%" class="error">
        <v-card width="100%">
            <v-card-title class="success white--text">Дякуємо! Звіт завантажено успішно</v-card-title>
            <v-card-text class="mt-6">
                <p>Період продажів: <b>{{toDate(dateFrom)}} - {{toDate(dateTo)}}</b></p>
                <div>Оброблено IMEI/SN: {{total}}</div>
                <div v-if="salesType == 'noSales'">Оброблено: <b>немає продажів</b></div>
                <v-alert type="warning" v-if="warningsTxt" outlined border="left" class="caption mt-4" icon="mdi-file-alert">
                    <p class="title">Увага!</p>
                    <p v-html="warningsTxt"></p>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="successDialog=false">Закрити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    mdiMicrosoftExcel
} from '@mdi/js'

export default {
    components: {},
    data: () => ({
        tab: 1,
        imeiType: '',
        imeiTypeS: '',
        salesType: '',
        exclelIcon: mdiMicrosoftExcel,
        show: false,
        errorText: '',
        dateFrom: "",
        dateTo: "",
        dateFromS: "",
        dateToS: "",
        firstRow: 1,
        shopS: {},
        confirmDialog: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        file: undefined,
        allShops: false,
        allShopsV: false,
        qty: {},
        qtyS: {},
        search: "",
        errorsDialog: false,
        successDialog: false,
        options: {
            page: 1,
            itemsPerPage: 40,
        },
        footerProps: {
            "items-per-page-options": [20, 40, 80, 100, 200],
            "items-per-page-text": "на стор.",
        },
        xlsLoading: false,
        xlsReportLoading: false,
        fileName: "",
        fileBlobURL: "",
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml",
        },
        headers: [{
                text: "Продаж З",
                align: "center",
                value: "start_date",
                sortable: true,
                width: "10%",
            },
            {
                text: "Продаж ПО",
                align: "center",
                value: "finish_date",
                sortable: true,
                width: "10%",
            },
            {
                text: "ТТ",
                align: "left",
                value: "shop_name",
                sortable: true,
                width: "25%",
            },
            {
                text: "MSC",
                align: "center",
                value: "msc",
                sortable: true,
                width: "15%",
            },
            {
                text: "Тип звіту",
                align: "center",
                value: "type",
                sortable: true,
                width: "15%",
            },
            {
                text: "Вивантажити",
                align: "center",
                value: "download",
                sortable: true,
                width: "5%",
            },
        ],
    }),
    methods: {
        ...mapActions([
            "touch",
            "getContactInfo",
            "getImeiTypeList",
            "getShopsForImeiReport",
            "setSnackbar",
            "sendImeiReportForm",
            "loadImeiReportsList",
            "exportImei"
        ]),
        ...mapGetters(["catalogTree"]),
        loadList() {
            this.options.page = 1;
            this.touch().then(() => {
                this.loadImeiReportsList({
                    imeiType: this.imeiTypeS,
                    dateFrom: this.dateFromS,
                    dateTo: this.dateToS,
                    shops: Object.keys(this.shopS)
                        .filter((el) => el && this.shopS[el])
                        .join(",")
                })

            })
        },
        Now() {
            return new Date().toISOString().slice(0, 10)
        },
        toDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        clearForm() {
            this.dateFrom = ""
            this.dateTo = ""
            this.file = undefined
            this.qty = {}
            this.qtyS = {}
            this.imeiType = ''
            this.salesType = undefined
        },
        clearSearchForm() {
            this.dateFromS = ""
            this.dateToS = ""
            this.shopS = {}
            this.imeiTypeS = ''
        },
        createGeneralXlsx() {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.xlsReportLoading = true;
            this.touch()
                .then(() => {
                    const options = {
                        dateFrom: this.dateFromS,
                        dateTo: this.dateToS,
                        shops: Object.keys(this.shopS)
                            .filter((el) => el && this.shopS[el])
                            .join(","),
                        imeiType: this.imeiTypeS
                    }
                    this.exportImei(options).then((data) => {
                        this.saveData(data, "imei-report.xlsx");
                        this.xlsReportLoading = false;
                    });
                })
                .catch((error) => console.log(error));
        },
        exportR(item) {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.xlsLoading = true;
            this.touch()
                .then(() => {
                    const options = {
                        dateFrom: item.start_date,
                        dateTo: item.finish_date,
                        shops: item.shop_id,
                        imeiType: item.type_id
                    }
                    this.exportImei(options).then((data) => {
                        this.saveData(data, "imei-report.xlsx");
                        this.xlsLoading = false;
                    });
                })
                .catch((error) => console.log(error));
        },
        saveData(data, fileName) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var buf = Buffer.from(data, "base64");
            const blob = new Blob([buf], {
                type: this.mime["xlsx"],
            });
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },
        checkShops() {
            this.touch()
                .then(() => {
                    this.getShopsForImeiReport({
                            imeiType: this.imeiType
                        })
                        .then(data => {
                            for (let el of data) {
                                if (el.is_inactive) {
                                    this.$set(this.qty, el.shop_id, '')
                                    this.$set(this.qtyS, el.shop_id, '')
                                }
                            }
                        })
                })
                .catch((error) => console.log(error));
            return true;
        },

        downloadReport() {
            this.touch()
                .then(() => {
                    let formData = new FormData()
                    if (this.file) {
                        formData.append("file", this.file, this.file.name)
                    }
                    formData.append("salesType", this.salesType)
                    formData.append("imeiType", this.imeiType)
                    formData.append("dateFrom", this.dateFrom)
                    formData.append("dateTo", this.dateTo)
                    formData.append("firstRow", this.firstRow)

                    for (let shopId in this.qtyS) {
                        if (this.qtyS[shopId]) {
                            formData.append("qty", shopId)
                        }
                    }

                    this.sendImeiReportForm(formData)
                        .then(data => {
                            if (data.errors || !this.total) {
                                this.errorsDialog = true
                            } else {
                                this.successDialog = true
                                this.loadList()
                            }
                        })
                        .catch((error) => {
                            this.setSnackbar(['error', error.response.statusText])
                        });


                })
                .catch((error) => console.log(error));
        },
        checkShopQty(shopId) {
            if (this.qtyS[shopId]) {
                this.qty[shopId] = shopId
            } else {
                this.qty[shopId] = ''
            }
        },
        checkAllShops() {
            for (let el of this.shopsList) {
                if (!el.is_inactive) {
                    const shopId = el.shop_id
                    this.$set(this.qtyS, shopId, this.allShops)
                    if (this.allShops) {
                        this.$set(this.qty, shopId, shopId)
                    } else {
                        this.$set(this.qty, shopId, '')
                    }
                }
            }
        },
        checkAllShopsV() {
            for (let el of this.shopsList) {
                const shopId = el.shop_id
                this.$set(this.shopS, shopId, this.allShopsV)
            }
        }
    },
    created() {
        this.getContactInfo()
            .then((data) => {
                if (!data.isSellToEmployee) {
                    this.show = true;
                    this.loadList()
                    this.getShopsForImeiReport()
                    this.getImeiTypeList()
                } else {
                    this.$router.push("/403")
                }
            })
            .catch((error) => console.log(error))
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo;
        },
        imeiTypeList() {
            return this.$store.state.reports.imeiTypeList
        },
        checkForm() {
            let qty = false
            for (let shopId in this.qtyS) {
                if (this.qtyS[shopId]) {
                    qty = true
                }
            }
            if (this.salesType) {
                if (this.salesType == 'sales') {
                    if (this.file && this.dateFrom && this.dateTo && this.imeiType && qty) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    if (this.dateFrom && this.dateTo && this.imeiType && qty) {
                        return false
                    } else {
                        return true
                    }
                }
            } else {
                return true
            }
        },
        checkFileForm() {
            return this.salesType == 'sales' ? false : true
        },
        formatedDateFrom() {
            return this.toDate(this.dateFrom);
        },
        formatedDateTo() {
            return this.toDate(this.dateTo);
        },
        formatedDateFromS() {
            return this.toDate(this.dateFromS);
        },
        formatedDateToS() {
            return this.toDate(this.dateToS);
        },
        imeiList() {
            return this.$store.state.reports.imeiList;
        },
        stockList() {
            return this.$store.state.reports.stockList;
        },
        stockDocs() {
            return this.$store.state.reports.stockDocs
        },
        stockDocsDialog: {
            get() {
                return this.$store.state.reports.stockDocsDialog
            },
            set(newN) {
                return this.$store.commit('setStockDocsDialog', newN)
            }
        },
        totalItems() {
            return this.imeiList.length
        },
        shopsList() {
            return this.$store.state.reports.shopsList;
        },
        filteredHeaders() {
            return this.headers.filter((h) => {
                return !h.hidden ?
                    this.$vuetify.breakpoint.xs ?
                    !h.hiddenXs :
                    this.$vuetify.breakpoint.smAndDown ?
                    !h.hiddenSm :
                    true :
                    false;
            });
        },
        listLoading() {
            return this.$store.state.reports.loading;
        },
        errorsTxt() {
            const errors = this.$store.state.reports.reportData.errors || []
            return errors.map(el => `<li>${el}`).join('')
        },
        warningsTxt() {
            const warnings = this.$store.state.reports.reportData.warnings || []
            return warnings.map(el => `<li>${el}`).join('')
        },
        total() {
            return this.$store.state.reports.reportData.total || 0
        }
    },
    watch: {
        salesType(v) {
            if (v == 'noSales') {
                this.file = undefined
            }
        }
    }
};
</script>

<style>
@media(max-width: 599px) {

    .card .v-slide-group__prev,
    .card .v-slide-group__next {
        display: none !important;
    }
}

@media (max-width: 512px) {
    .v-data-table.report>.v-data-table__wrapper td {
        padding: 0 2px;
        font-size: 0.75rem !important;
        font-weight: 300;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;
    }
}
</style>
