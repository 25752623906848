<template>
    <ReportsPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ReportsPage from '../components/ImeiReportsPage.vue'

export default {
    data: () => ({
    }),
    components: {
       ReportsPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>
